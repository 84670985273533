import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  AllCategoryButton,
  AllCategorySection,
  Container,
  Heading,
  ListingHeading,
  MiddleBodyAdWrapper,
} from './Hybrid.styles';
import CategoryPopUp from '@/components/CategoryPopUp';
import { conditionalForMiddlebodyRender } from '@/utils/conditionalForMiddlebodyRender';
import 'aos/dist/aos.css';
import { CATEGORY_TYPE, SHOP_DOMAIN_ROUTE } from '@/utils/constants';
import DesktopDsAds from '../DesktopDsAds';
import SortingAndFiltersTab from '@/components/SortingAndFiltersTab/SortingAndFiltersTab';
import { FilterDrawerMidd6 } from './FilterDrawerMidd6/FilterDrawerMidd6';
import { FilteredAndSortItems } from './FilteredAndSortItems/FilteredAndSortItems';
import useGetAnimationTranslation from '../MiddleBody5/utils/useGetAnimationTranslation';
import { useSSRSelector } from '@/redux/ssrStore';
import { fetchCatalogItemsByCategoryId, resetCatalogItems } from '@/redux/actions';
import FlatList from '@/components/FlatList';
import CategoryItems from './CategoryItems';
import { persistScrollPosition } from '@/containers/Home/hooks/useScrollAnchor';
import { IS_SERVER } from '@/utils/checkRenderEnv';
import { scrollOnPageLoad } from '@/utils/scrollOnPageLoad';
import checkIfShowDummyProduts from '@/utils/checkIfShowDummyProducts';
import checkIfThemePreview from '@/utils/checkIfThemePreview';
import PromoSlider from 'src/components/CouponsAndVouchers/PromoSlider';
import { PROMO_APPLICABILITY_TYPES } from 'src/constants/promos';

const HybridListing = (props) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showFilterDrawer, setShowFilterDrawer] = useState(null);
  const filterDrawerWrapperRef = useRef();

  const categoryIdsFetched = useRef({});

  const { translationDistanceForAnimation, middleBodyContainerRef } =
    useGetAnimationTranslation(showFilterDrawer);

  const {
    catalogData: { categories, catalog_items, productTags },
    commonData: {
      heightCalculation: { heroBannerHeightCalculated },
    },
    sortType,
    filterAppliedCounter,
  } = useSelector((state) => ({
    catalogData: state.catalogReducer,
    commonData: state.commonReducer,
    sortType: state.catalogReducer.sortType,
    filterAppliedCounter: state.catalogReducer.filterAppliedCounter,
  }));

  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));

  const { theme, store_id, premium } = storeData;

  const shouldScrollToClickedItem = !IS_SERVER && scrollOnPageLoad().toScroll;
  const showDummyData = checkIfShowDummyProduts(storeData);
  const isPreview = checkIfThemePreview();
  const isTag = (categoryId) =>
    productTags?.length && productTags.findIndex((tag) => tag.id === categoryId) >= 0;

  useEffect(() => {
    if (shouldScrollToClickedItem) {
      persistScrollPosition();
    } else {
      !showDummyData && dispatch(resetCatalogItems());
    }
  }, []);

  useEffect(() => {
    // don't scroll to category when we have scroll position
    if (router?.query?.cid && !shouldScrollToClickedItem) {
      document.getElementById(`listing-${router.query.cid}`)?.scrollIntoView();
    }
  }, [router?.query?.cid, catalog_items]);

  const toggleCategoryModal = () => {
    setShowCategoryModal(!showCategoryModal);
  };

  function handleShowFilterDrawer(e) {
    e?.stopPropagation();
    setShowFilterDrawer(!showFilterDrawer);
  }

  const returnCategoryData = (categoryId) => {
    return catalog_items[categoryId];
  };

  const loadMoreData = (categoryId) => {
    if (categoryIdsFetched.current[categoryId]) {
      return;
    }

    const payload = {
      store_id: store_id,
      category_id: categoryId,
      category_type: isTag(+categoryId) ? CATEGORY_TYPE.TAG : CATEGORY_TYPE.CATEGORY,
      page_no: 0,
    };

    dispatch(fetchCatalogItemsByCategoryId(payload));
    categoryIdsFetched.current[categoryId] = true;
  };

  const renderListing = (item) => {
    return (
      <React.Fragment key={item?.id}>
        <ListingHeading id={`listing-${item?.id}`}>
          {item?.name || item.hasOwnProperty('is_custom_tag') || 'Available Items'}
        </ListingHeading>
        <div className="mb6-category-item-body">
          <PromoSlider
            type={
              isTag(item?.id)
                ? PROMO_APPLICABILITY_TYPES.TAG
                : PROMO_APPLICABILITY_TYPES.CATEGORY
            }
            typeId={item?.id}
            customClasses="md:tw-px-[32px]"
          />
          <FlatList
            renderOnVisible
            isLoaderVisible={false}
            isNext={showDummyData ? false : !returnCategoryData(item?.id)}
            onScrollEnd={() => loadMoreData(item?.id)}
            renderList={() => <CategoryItems category={item} />}
          />
        </div>
      </React.Fragment>
    );
  };
  const displayItems = () => {
    /** While displaying categories and product tags in Website Builder, there is a performance issue. The browser freezes, hence as a hack we are restricting display of total categories and items within them for now. This can be properly fixed by virtual list in near future. No impact on CFE as of now */

    const itemsToDisplay = showDummyData ? categories : [...productTags, ...categories];
    const displayLimit = props.displayMiddleBodyInWebsiteBuilder ? 5 : undefined;

    /* although we don't need this filter as we will only have active tags, 
    but the tags are cached on client side (and some inactive tags may be there on client end)
    therefore, for the safe side we are keeping it for now and will remove this in future. */
    const filteredItems = itemsToDisplay.filter((item) =>
      item.hasOwnProperty('is_active') ? item.is_active : true
    );

    return filteredItems?.slice(0, displayLimit)?.map((item) => renderListing(item));
  };

  return (
    conditionalForMiddlebodyRender(
      heroBannerHeightCalculated,
      theme?.theme_id || theme?.theme_id === 0,
      router?.route?.includes('shop')
    ) && (
      <>
        <MiddleBodyAdWrapper>
          {props.sortAndFiltersEnabled && (
            <FilterDrawerMidd6
              showFilterDrawer={showFilterDrawer}
              handleShowFilterDrawer={handleShowFilterDrawer}
              filterDrawerWrapperRef={filterDrawerWrapperRef}
              hideCollectionFilter={true}
              hideCategoryFilter={false}
              hideTags={true}
            />
          )}
          <Container
            ref={middleBodyContainerRef}
            trasnlationDist={translationDistanceForAnimation.current}
            showFilterDrawer={showFilterDrawer}
          >
            {router.route !== SHOP_DOMAIN_ROUTE && <Heading>Categories</Heading>}
            {props.sortAndFiltersEnabled && (
              <SortingAndFiltersTab
                middleBodyRef={middleBodyContainerRef}
                showFilterDrawer={showFilterDrawer}
                handleShowFilterDrawer={handleShowFilterDrawer}
                filterDrawerWrapperRef={filterDrawerWrapperRef}
              />
            )}
            {(!!sortType || !!filterAppliedCounter) && props.sortAndFiltersEnabled ? (
              <FilteredAndSortItems />
            ) : (
              displayItems()
            )}
            {theme?.colors && !showCategoryModal && categories?.length > 0 && (
              <AllCategorySection>
                <AllCategoryButton
                  backgroundColor={premium ? theme?.colors?.primary_color : '#000000'}
                  onClick={toggleCategoryModal}
                >
                  All Categories
                </AllCategoryButton>
              </AllCategorySection>
            )}
          </Container>
          {showCategoryModal && (
            <CategoryPopUp
              toggleModal={toggleCategoryModal}
              isVisible={showCategoryModal}
            ></CategoryPopUp>
          )}
          {!isPreview && (
            <DesktopDsAds
              mr={'mr40px'}
              pt={'pt40px'}
              mb={'digital-showroom__ads__mb15rem'}
              ml={''}
              id={'DesktopDsAds-1'}
            />
          )}
        </MiddleBodyAdWrapper>
      </>
    )
  );
};

export default HybridListing;
