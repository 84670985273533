import React from 'react';
import { useSelector } from 'react-redux';
import { useItemClick } from '@/hooks/useItemClick';
import { CARD_SKELETON } from '@/utils/constants';
import { Card } from '../../Card';
import { MobileDsAds } from '../MiddleBody5/components';

const CategoryItems = ({ category }) => {
  const {
    catalogData: { catalog_items },
  } = useSelector((state) => ({
    catalogData: state.catalogReducer,
  }));

  const [onItemClick] = useItemClick();

  const returnCategoryData = (categoryId) => {
    return catalog_items[categoryId];
  };

  const categoryData = returnCategoryData(category?.id);

  return categoryData ? (
    categoryData.map((data) => (
      <React.Fragment key={data.id}>
        <Card
          key={data.id}
          data={data}
          onItemClick={() => onItemClick(data)}
          showButton={true}
          noTags={category.hasOwnProperty('is_custom_tag')}
        />
        <MobileDsAds data={data} />
      </React.Fragment>
    ))
  ) : (
    <>
      {new Array(3).fill(0).map((_, index) => (
        <Card skeleton skeletonImage={CARD_SKELETON} key={index} />
      ))}
    </>
  );
};

export default CategoryItems;
